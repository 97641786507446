import About from "./about.jpg";
import Contact from "./contact.jpg";
import Home from "./home.jpg";
import Projects from "./projects.jpg";

export {
    About, 
    Contact,
    Home,
    Projects
}